import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "info",
    meta: { titleName: "绑定信息" },
    component: () => import("@/views/info.vue"),
  },
  {
    path: "/list",
    name: "list",
    meta: { titleName: "投票列表" },
    component: () => import("@/views/list.vue"),
  },
  {
    path: "/Detail",
    name: "Detail",
    meta: { titleName: "投票详情" },
    component: () => import("@/views/detail.vue"),
  },
  {
    path: "/questionnaire",
    name: "Questionnaire",
    component: () => import("@/views/questionnaire.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
